import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import FullScreenSection from "../components/full-screen-section"
import Seo from "../components/seo"

import styled from 'styled-components'

const IndexPage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        bg1:

        file(relativePath: { eq: "bg-ae1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg2:
        file(relativePath: { eq: "bg-ae2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg3:
        file(relativePath: { eq: "bg-ae4.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }        

        flyer:
        file(relativePath: { eq: "flyer_mac.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }        

      }
    `
  )

  // const data2 = useStaticQuery(
  //   graphql`
  //     query {
  //       desktop: file(relativePath: { eq: "obj.jpg" }) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_noBase64
  //           }
  //         }
  //       }
  //     }
  //   `
  // )  

  const bg1 = data.bg1.childImageSharp.fluid
  const bg2 = data.bg2.childImageSharp.fluid
  const bg3 = data.bg3.childImageSharp.fluid
  const flyerMac = data.flyer.childImageSharp.fluid

  return (
    <Layout>
      <Seo title="Actividades educativas" />

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon g-0 d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-column `}>
            <p className={`text-white headline pb-0 pointer-cursor`} onClick={() => { window.scrollTo(0, 1000) }}><span className={`bg-black`}>Actividades educativas /</span><span className={`fs-5 bg-black d-none d-md-inline-block d-lg-inline-block d-xl-inline-block`}>Actividades realizadas por parte de nuestro equipo de trabajo y que son gratuitas para la comunidad.</span></p>
          </div>

          <div className={`row d-flex flex-1`}>

            <div className={`col pt-1 d-flex flex-column`}>

              {/*<div className={`col-lg-6 col-xl-6 col`}>

                <p className={`text-white text-end bg-black mt-5 pb-0 mb-0 pointer-cursor headline`} onClick={() => { window.scrollTo(0, 1000) }}>Actividades educativas /</p><p className={`text-black text-end pt-0 mt-0`}>Actividades realizadas por parte de nuestro equipo de trabajo y que son gratuitas para la comunidad</p>
                

              </div>*/}

              <div className={`col-lg-8 col-xl-8 col d-flex flex-1 flex-column flex-items-middle space-between`}>

                <div className={`col-6 offset-lg-4 offset-xl-4 pointer-cursor`} onClick={() => { document.getElementById('1a').scrollIntoView(); }}><p className={`text-right mb-0 pb-0 bg-gray`}><span className={`text-white text-end pb-0 mb-0 me-1 fs-1`}>Charla <i className={`fs-3 fa fa-chevron-down pe-2`} aria-hidden="true"></i></span></p><span className={`text-black`}>1. Arte integrado a la arquitectura y el Taller de Diseño Integrado</span></div>
                <div className={`col-5 offset-lg-3 offset-xl-3 pointer-cursor`} onClick={() => { document.getElementById('2').scrollIntoView(); }}><p className={`text-right mb-0 pb-0 bg-gray`}><span className={`text-white text-end pb-0 mb-0 me-2 fs-1`}>Workshop <i className={`fs-3 fa fa-chevron-down pe-2`} aria-hidden="true"></i></span></p><span className={`text-black`}>2. Digitalizando la ciudad. Arte, tecnología y educación.</span></div>
                <div className={`col-7 offset-lg-5 offset-xl-5 pb-5 pointer-cursor`} onClick={() => { document.getElementById('3').scrollIntoView(); }}><p className={`text-right mb-0 pb-0 bg-gray`}><span className={`text-white text-end pb-0 mb-0 ms-5 fs-1 mb-5`}>Mesa redonda <i className={`fs-3 fa fa-chevron-down pe-2`} aria-hidden="true"></i></span></p><span className={`text-black`}>3. Resignificación del concepto de patrimonio y del espacio público. La situación del mural hoy.</span></div>

              </div>
            </div>                        

          </div>

          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>

      </FullScreenSection>

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

          <div id={`1a`} className={`row d-flex flex-column p-1`}>
            <p className={`text-black text-start ps-1 fs-5 mt-0 pt-0 pb-0 mb-0`}><b>1a.- “Vanguardias y contexto sociopolítico. Arte Integrado”.</b><br />Charla - Miércoles 29 de Junio 2022 12:00 hrs. <br />Mónica Salinero Rates</p>
          </div>

          <div className={`row d-flex flex-1`}>

            <div className={`col-10 offset-1 pt-1 d-flex flex-column`}>

                <iframe className={`youtube-embed-wrapper`} src='https://www.youtube.com/embed/k4RyPEoUdqk?list=PL4imoma2E8q-fuXjw-ZOOXzB8SaeQHyl8' title='Actividad Educativa 1 de CURVA DE LA LUZ. Charla "Arte Integrado" Mónica Salinero Rates.' frameBorder={'0'} allowFullScreen></iframe>

            </div>                        

          </div>

          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>

      </FullScreenSection>

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

          <div className={`row d-flex flex-column p-1`}>
            <p className={`text-black text-start ps-1 fs-5 mt-0 pt-0 pb-0 mb-0`}><b>1b.- “El Taller de Diseño Integrado” Stella Salinero Rates.</b><br />Charla - Miércoles 29 de Junio 2022 12:50 hrs.<br />Stella Salinero Rates.</p>
          </div>

          <div className={`row d-flex flex-1`}>

            <div className={`col-10 offset-1 pt-1 d-flex flex-column`}>

                <iframe className={`youtube-embed-wrapper`} src='https://www.youtube.com/embed/uSwbD6E3UCA' title='Actividad Educativa 1 de CURVA DE LA LUZ. Charla "Arte Integrado" Stella Salinero Rates.' frameBorder={'0'} allowFullScreen></iframe>

            </div>                        

          </div>

          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>

      </FullScreenSection>      

{/*      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg2}
          backgroundColor={`#040e18`}
        >

          <div id="2" className={`row d-flex flex-1`}>

            <div className={`col-lg-5 col-xl-5 offset-lg-1 offset-xl-1 col d-flex flex-column`}>

              <div className={`pt-5`}>

                <p className={`text-black text-start ps-1 fs-5 mb-0 pb-0`}><b>2.- "Digitalizando la ciudad. <br />Arte, tecnología y educación"</b></p>
                <p className={`text-black text-start ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Workshop de Fotogrametría - Viernes 26 Agosto 2022.</p>
                <p className={`text-black text-start ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Kurt Malonnek.</p>
                <p className={`text-black text-start ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>11:30 a 14:00 hrs.</p>
                <p className={`text-black text-start ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Consultas e inscripción previa: curvadelaluz@gmail.com</p>
                <p className={`text-black text-start ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Museo de Arte Contemporáneo - Parque Forestal.</p>
              
              </div>

              </div>
                                    

          </div>    
            
            <div className={`row ribbon no-gutters`}>

              <div className={`col-3 ribbon-blue`}></div>
              <div className={`col-2 ribbon-red`}></div>
              <div className={`col-4 ribbon-navy`}></div>
              <div className={`col-3 ribbon-gray`}></div>

            </div>

        </BackgroundImage>

      </FullScreenSection>*/}

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg2}
          backgroundColor={`#040e18`}
        >

          <div id="2" className={`row d-flex flex-column p-1`}>
            <p className={`text-black text-start ps-1 fs-5 mb-0 pb-0`}><b>2.- "Digitalizando la ciudad. <br />Arte, tecnología y educación"</b></p>
            <p className={`text-black text-start ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Workshop de Fotogrametría - Viernes 26 Agosto 2022.</p>
          </div>

          <div className={`row d-flex flex-1`}>

            <div className={`col-10 offset-1 pt-1 d-flex flex-column`}>

                <iframe className={`youtube-embed-wrapper`} src='https://www.youtube.com/embed/fkcjAi_iIDE' title='Extracto Workshop Fotogrametría - "Digitalizando la ciudad. Arte Tecnología y Educación"' frameBorder={'0'} allowFullScreen></iframe>

            </div>                        

          </div>

          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>

      </FullScreenSection>

{/*      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg3}
          backgroundColor={`#040e18`}
        >

          <div id="3" className={`row d-flex flex-1`}>

              <div className={`col-lg-6 col-xl-6 offset-lg-6 offset-xl-6 col d-flex`}>

                <div className={`d-flex flex-1 flex-column items-middle pe-5 ps-0`}>

                <p className={`text-black text-right ps-1 fs-5 mb-0 pb-0`}><b>3.- "Resignificación del concepto de patrimonio y del espacio público. La situación del mural hoy".</b></p>
                <p className={`text-black text-right ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Mesa redonda - Sábado 10 de Septiembre</p>                  
                <p className={`text-black text-right ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>2022</p>                  
                <p className={`text-black text-right ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>12:00 a 13:45 hrs.</p>                  
                <p className={`text-black text-right ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Invitados:</p>                  
                <p className={`text-black text-right ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Pelagia Rodriguez</p>                  
                <p className={`text-black text-right ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Angela Benavente</p>                  
                <p className={`text-black text-right ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Carolina Pelegrí y</p>                  
                <p className={`text-black text-right ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Eduardo Martínez Bonati.</p>                  
                <p className={`text-black text-right ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Museo de Arte Contemporáneo - Parque Forestal.</p>

                </div>

              </div>
                                    

          </div>    
            
            <div className={`row ribbon no-gutters`}>

              <div className={`col-3 ribbon-blue`}></div>
              <div className={`col-2 ribbon-red`}></div>
              <div className={`col-4 ribbon-navy`}></div>
              <div className={`col-3 ribbon-gray`}></div>

            </div>

        </BackgroundImage>

      </FullScreenSection>*/}

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg3}
          backgroundColor={`#040e18`}
        >

          <div id="3" className={`row d-flex flex-column p-1`}>
                <p className={`text-black ps-1 fs-5 mb-0 pb-0`}><b>3.- "Resignificación del concepto de patrimonio y del espacio público. La situación del mural hoy".</b></p>
                <p className={`text-black ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>Mesa redonda - Sábado 10 de Septiembre</p>                  
                <p className={`text-black ps-1 fs-6 mt-0 pt-0 pb-0 mb-0`}>2022</p>                  
          </div>

          <div className={`row d-flex flex-1`}>

            <div className={`col-10 offset-1 pt-1 d-flex flex-column`}>

                <iframe className={`youtube-embed-wrapper`} src='https://www.youtube.com/embed/dKO0jpETqjw' title='Resignificación del concepto de patrimonio y del espacio público. La situación del mural hoy' frameBorder={'0'} allowFullScreen></iframe>

            </div>                        

          </div>

          <div className={`row ribbon no-gutters`}>

            <div className={`col-3 ribbon-blue`}></div>
            <div className={`col-2 ribbon-red`}></div>
            <div className={`col-4 ribbon-navy`}></div>
            <div className={`col-3 ribbon-gray`}></div>

          </div>

        </BackgroundImage>

      </FullScreenSection>            

        <div className={"modal fade"} id="repo-ae1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className={"modal-dialog"}>
            <div className={"modal-content"}>
              <div className={"modal-header"}>
                <h5 className={"modal-title"} id="staticBackdropLabel"></h5>
                <button type="button" className={"btn-close"} data-bs-dismiss="modal" aria-label="Cerrar"></button>
              </div>
              <div className={"modal-body"}>
                <StaticImage
                  alt={`Flyer Mac`}
                  src="../images/flyer_mac.jpeg"
                  width={600}
                  quality={95}
                  style={{ 
                  }}
                />                 
              </div>
            </div>
          </div>
        </div>      

    </Layout>
  )

}

const StyledBackgroundSection = styled(IndexPage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default IndexPage
